.page-header {

  &::before {
    display: block;
    width: 100%;
    height: 5px;
    background: $resolution-blue;
    content: '';
  }
}
