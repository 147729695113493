.menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
  width: 100%;
  padding: 0 20px;
  background: $resolution-blue;


  &.active {
    display: block;
  }

  @include large-up {
    position: relative;
    display: block;
    padding: 0;
    border-top: 1px solid rgba($black, 0.2);
    border-bottom: 1px solid rgba($black, 0.2);
    background: none;

    // @include clearfix;
    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }
}

.menu-open {
  display: block;
  padding: 9px 0;
  font-family: $franklin-gothic-cond;
  font-size: 18px;
  font-weight: 600;
  color: $rosewood;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border-top: 1px solid $silver;
  border-bottom: 1px solid $silver;

  @include large-up {
    display: none;
  }
}

.menu-close {
  display: block;
  padding: 9px 0;
  font-family: $franklin-gothic-cond;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: $frangipani;
  text-transform: uppercase;
  text-decoration: none;
  border-top: 5px solid rgba($black, 0.5);
  border-bottom: 1px solid rgba($black, 0.5);

  @include large-up {
    display: none;
  }
}