.page-footer {
  display: block;
  width: 100%;
  padding: 13px 18px 19px;
  font-family: $franklin-gothic;
  font-size: 12px;
  line-height: 1.34;
  color: rgba($white, 0.5);
  background: $resolution-blue;

  @include medium-up {
    padding: 12px 40px;
  }
}
