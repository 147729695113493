.user-nav {
  position: absolute;
  top: 15px;
  right: 41px;
  font-family: "franklin-gothic-urw", sans-serif;
  font-weight: 500;
  color: #42413e;
  font-size: 13px;
  line-height: 20px;
  @include large-up {
    top: -106px;
  }

  a {
    color: $polo-blue;
    text-decoration: none;
    @include large-up {
      font-weight: 500;
      color: $rosewood;
    }
  }
}