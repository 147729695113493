.card {
  position: relative;
  padding: 18px 18px 37px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 2px solid $white;
  border-radius: 6px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.18);

  @include medium-up {
    padding: 10px 40px 20px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
