/*!
*   WS Starter
*   Designed by LRS Web Solutions (http://lrswebsolutions.com)
*   Visit http://lrswebsolutions.com
*/

html,
body {
  position: relative;
  width: 100%;
  min-width: 320px;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


html {
  font-family: $georgia;
  color: $merlin;
  background-color: $pearl-bush;
  background-image: url('../images/diagonal-lines.png');
}

body {
  min-height: 100%;
  background-image: linear-gradient(#fff 0,rgba(255,255,255,.65) 300px,rgba(255,255,255,0) 85%);
  // @include linear-gradient($white 0%, rgba($white, 0.65) 300px, rgba($white, 0) 85%, $fallback: transparent);
}
