.copyright {

  span {
    display: block;

    @include medium-up {
      display: inline;
      font-size: 12px;
    }
  }
}
