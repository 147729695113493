.page {
  min-width: 320px;
  padding: 0 20px;
  margin: 0;

  @include large-up {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }
}
