/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/


// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
@import 'presets/grid-settings';
@import 'presets/helper-classes';

// Normalize
@import 'vendor/normalize';

// Bourbon
// @import 'vendor/bourbon/bourbon';

// Neat
// @import 'vendor/neat/neat';

// Layout
@import 'layout/page';
@import 'layout/header';
@import 'layout/content';
@import 'layout/footer';

// Components
@import 'components/general';
@import 'components/copyright';
@import 'components/nav-primary';
@import 'components/branding';
@import 'components/menu';
@import 'components/location';
@import 'components/card';
@import 'components/users';
