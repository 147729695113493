.nav-primary {
    @include large-up {
        position: relative;
        left: -50%;
        float: right;
    }

    .ul1 {
        @include large-up {
            position: relative;
            left: 50%;
        }

        margin: 0;
        padding: 0;
        list-style: none;
    }

    .li1 {
        @include large-up {
            float: left;
            border-bottom: 0;
        }

        position: relative;
        display: block;
        margin: 0;
        padding: 0;

        &:first-child {
            .a1 {
                &::before {
                    @include large-up {
                        display: none;
                    }
                }
            }
        }

        &:last-child {
            .a1 {
                border-bottom-color: rgba($black, 0.5);
            }
        }

        &:hover {
            .a1 {
                @include large-up {
                    color: $resolution-blue;
                }
            }

            .ul2 {
                @include large-up {
                    display: block;
                }
            }
        }

        &.active {
            .a1 {
                &::after {
                    @include large-up {
                        position: absolute;
                        bottom: -4px;
                        left: 14px;
                        display: block;
                        width: calc(100% - 28px);
                        height: 4px;
                        background: rgba($white, 0.2);
                        content: '';
                    }
                }
            }

            .indicator {
                &::after {
                    content: '\f068';
                }
            }
        }
    }

    .a1 {
        @include large-up {
            padding: 7px 14px;
            border-bottom: 0;
            color: $rosewood;
            text-align: center;
        }

        position: relative;
        display: block;
        padding: 9px 0;
        border-bottom: 1px solid rgba($white, 0.2);
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        font-family: $franklin-gothic-cond;
        line-height: 24px;
        cursor: default;

        &:link {
            cursor: pointer;
        }

        &::before {
            @include large-up {
                position: absolute;
                top: 10px;
                left: 0;
                display: block;
                width: 1px;
                height: 18px;
                background-color: $cotton-seed;
                content: '';
            }
        }
    }

    .indicator {
        @include large-up {
            display: none;
        }

        position: absolute;
        top: 4px;
        right: 0;
        display: block;
        width: 48px;
        height: 100%;
        cursor: pointer;
        &::after {
            position: absolute;
            top: 9px;
            right: 0;
            display: block;
            color: $frangipani;
            content: '\f067';
            font-weight: 400;
            font-size: 14px;
            font-family: $font-awesome;
        }
    }

    .ul2 {
        @include large-up {
            position: absolute;
            left: calc(50% - 120px);
            display: none;
            padding: 17px 20px 25px;
            width: 240px;
            background: rgba($resolution-blue, 0.95);
        }

        position: relative;
        display: none;

// display: block;
        padding: 0;
        list-style: none;
        &:before {
            @include large-up {
                position: absolute;
                top: -8px;
                left: calc(50% - 10px);
                display: block;
                width: 0;
                height: 0;
                border-width: 0 10px 8px 10px;
                border-style: solid;
                border-color: transparent transparent rgba($resolution-blue, 0.95) transparent;
                content: '';
            }
        }
    }

    .li2 {
        @include large-up {
            border-bottom-style: solid;
        }

        display: block;
        margin: 0;
        padding: 0;
        border-bottom: 1px dashed rgba($white, 0.2);
        &:last-child {
            @include large-up {
                border-bottom: 0;
            }
        }
    }

    .a2 {
        @include large-up {
            color: $white;
            text-align: center;
        }

        display: block;
        padding: 12px 20px 11px;
        color: $polo-blue;
        text-decoration: none;
        font-weight: 500;
        font-size: 13px;
        font-family: $franklin-gothic;
        &:hover {
            @include large-up {
                color: $frangipani;
            }
        }
    }
}
