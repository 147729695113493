.page-header .location {
  display: block;
  padding: 25px 0 24px;
  font-family: $franklin-gothic-cond;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: $white;


  @include large-up {
    position: absolute;
    top: -65px;
    right: 41px;
    display: block;
    padding: 0;
    font-family: $franklin-gothic;
    font-weight: 500;
    color: $merlin;
    text-align: right;
  }

  a {
    color: $polo-blue;
    text-decoration: none;

    @include large-up {
      font-weight: 500;
      color: $rosewood;
    }
  }
}
