.branding {

  .logo {
    display: block;
    padding: 20px 0 20px 20px;

    // @include clearfix;
    &::after {
      clear: both;
      content: "";
      display: table;
    }

    @include medium-up {
      padding: 20px 0 20px 40px;
    }
  }

  a {
    text-decoration: none;
  }

  .mark {
    display: block;
    float: left;
    width: 60px;
    height: 60px;
    background: url('../images/ucci-logo.svg');
    background-position: 0 0;

    @include medium-up {
      width: 80px;
      height: 80px;
    }
  }

  .text {
    display: block;
    float: left;
    padding: 6px 8px;
    margin: 0;
    font-family: $times;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.5px;
    color: $resolution-blue;
    text-decoration: none;

    @include medium-up {
      padding: 6px 16px;
      font-size: 34px;
    }

    @media all and (min-width: 1080px) {
      padding: 20px 0 21px 16px;
      font-size: 39px;
    }

    i {
      color: $bali-hai;
    }

    span {
      display: block;

      @media all and (min-width: 1080px) {
        display: inline;
      }
    }
  }
}
